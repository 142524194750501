import React, { Component } from "react";
import Spinner from "../Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../css/BidCardDashboard.css"; // Create a separate CSS file for styling

class BidCardDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ids: "",
      donationType: "",
      customAmount: "",
      dollarAmount: "",
      error: null,
      searchResults: [],
      data: [],
      isLoading: false,
      categories: [],
      expandedRowID: null,
      expandedRowType: null,
      notesText: "",
      receiptInfo: null,
      category: "all",
    };

    this.intervalId = null;
  }

  fetchData = () => {
    fetch(`${process.env.REACT_APP_API_URL}/aura/purchases/wine`)
      .then((response) => response.json())
      .then((dataList) => {
        if (Array.isArray(dataList)) {
          if (dataList.length !== 0) {
            let myDataList = dataList.map((item) => {
              if (item.corks && typeof item.corks === "string") {
                try {
                  item.corks = JSON.parse(item.corks);
                } catch (error) {
                  console.error("Error parsing corks:", error);
                }
              }
              return item;
            });
  
            myDataList.sort((a, b) => b.id - a.id);
  
            this.setState({ searchResults: myDataList });
          }
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  };
  

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      this.fetchData();
      this.setState({ isLoading: false });
    });

    this.intervalId = setInterval(this.fetchData, 5000);
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  render() {
    return (
      <>
        <main>

          <section className="container stylization maincont">
          <h1>
              <span>Wine Wall Dashboard</span>{this.props.titleSuffix}
            </h1>
            {this.state.searchResults.length > 0 && (
              <div className="cart-items-wrap">
                <table className="order-status-table">
                  <thead>
                    <tr>
                      <th className="order-status-info">Name</th>
                      <th className="order-status-info">Wine Corks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.searchResults.map((result, index) => (
                      <tr
                        key={index}
                        style={{
                          backgroundColor: "transparent",
                        }}
                      >
                        <td
                          style={{
                            color: "rgba(0, 0, 0, 1)",
                            backgroundColor: "transparent",
                          }}
                        >
                          {result.firstname} {result.lastname}
                        </td>
                        <td
                          style={{
                            color: "rgba(0, 0, 0, 1)",
                            backgroundColor: "transparent",
                          }}
                        >
                          {Array.isArray(result.corks) ? result.corks.join(', ') : result.corks}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </section>
          <ToastContainer />
          {this.state.isLoading && <Spinner />}
        </main>
      </>
    );
  }
}

export default BidCardDashboard;