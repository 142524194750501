import React, { useState, useEffect } from "react";
import "../css/Pledges.css"; // Create a separate CSS file for styling
import LogoHeader from "./LogoHeader";
import Spinner from "../Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckinForm from "./CheckinForm";
import { faDisplay, faList, faDollar } from "@fortawesome/free-solid-svg-icons";
import BidCardDashboard from "./BidCardDashboard";
import { useNavigate, useLocation } from "react-router-dom";

const welcomeMessageBarcode = (
  <span className="message success">Scan the first attendee</span>
);

const menuItems = [
  {
    label: "Wine Wall Dashboard",
    link: "dashboard",
    icon: faList,
    welcome: "",
  },
];

const CheckinComponent = ({page}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isTest, setIsTest] = useState(window.location.hostname.includes('test.'));
  const [titleSuffix, setTitleSuffix] = useState("")

  const [isLoading, setIsLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(() => {
    const savedPageIndex = localStorage.getItem('pageIndex');
    return savedPageIndex !== null ? parseInt(savedPageIndex, 10) : 0;
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (isTest) {
      setTitleSuffix(<> [Test]</>)
    } else {
      setTitleSuffix(<></>)
    }

    if (page) {
      const menuItemIndex = menuItems.findIndex(item => item.link === page);

      console.log(menuItemIndex);
      if (menuItemIndex !== -1) {
        setPageIndex(menuItemIndex);

      }
    } else {
      const savedPageIndex = localStorage.getItem('pageIndex');
      
      setPageIndex(savedPageIndex !== null ? parseInt(savedPageIndex, 10) : 0);
    }
  }, [location.search, navigate, location.pathname]);


  useEffect(() => {
    localStorage.setItem('pageIndex', pageIndex);
  }, [pageIndex]);


  return (
    <>
      <LogoHeader
        selectedType={pageIndex}
        setSelectedType={setPageIndex}
        menuItems={menuItems}
      />
      <BidCardDashboard titleSuffix={titleSuffix} />
    </>
  );
};

export default CheckinComponent;